@mixin xs {
  @media (max-width: 0) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 320px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 414px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: 415px) and (max-width: 550px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: 551px) and (max-width: 820px) {
    @content;
  }
}
