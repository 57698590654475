@import "../../breakpoints.scss";

.rightSide {
  display: none;
  width: 20%;

  @include sm {
    display: none;
    width: 70%;
    margin: 20px 0 0px 0;
  }

  @include md {
    display: none;
    width: 30%;
  }

  @include lg {
    display: none;
    width: 30%;
  }

  span {
    font-size: 18px;
    font-family: "Gilroy", sans-serif;

    @include md {
      font-size: 15px;
    }
  }

  .posters {
    margin-top: 20px;

    .poster {
      cursor: pointer;
      width: 100%;
      margin-bottom: 15px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
