@import "../../breakpoints.scss";

.home {
  background-color: #f0f0f0;
  min-height: 100vh;
  width: 100vw;

  @include sm {
    height: auto;
  }

  .homecontainer {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin: 30px 30px 0 30px;
    height: 75vh;

    @include sm {
      margin: 0;
      gap: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: auto;
    }

    @include md {
      margin: 0;
      gap: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: auto;
    }

    @include lg {
      margin: 0;
      gap: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: auto;
    }

    @include xl {
      margin: 0;
      gap: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: auto;
    }
  }
}
