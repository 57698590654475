@import "../../breakpoints.scss";

.itemSk {
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 0px 10px 10px;
  animation: skeleton 1s ease infinite alternate;

  @include sm {
    padding: 0px 0px 0px 5px;
  }

  @include md {
    padding: 0px 0px 0px 5px;
  }

  @include lg {
    padding: 0px 0px 0px 5px;
  }

  @include xl {
    padding: 0px 0px 0px 5px;
  }

  @keyframes skeleton {
    to {
      opacity: 0.5;
    }
  }

  .iconSk {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .payTextSk {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
    width: 50%;
    gap: 5px;

    .mainTextSk {
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 20px;
    }

    .textTimeSk {
      background-color: rgba(0, 0, 0, 0.3);
      width: 20%;
      height: 10px;
    }
  }

  .amountSk {
    background-color: rgba(0, 0, 0, 0.3);
    height: 100%;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 0px 10px 10px 0px;
    margin-left: auto;

    @include sm {
      width: 20%;
      padding: 0;
      margin-left: auto;
    }

    @include md {
      width: 20%;
      padding: 0;
      margin-left: auto;
    }

    @include lg {
      width: 20%;
      padding: 0;
      margin-left: auto;
    }

    @include xl {
      width: 20%;
      padding: 0;
      margin-left: auto;
    }
  }
}
