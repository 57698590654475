@import "../../breakpoints.scss";

.sidebar {
  width: 25%;

  @include sm {
    width: 70%;
    margin: 20px 0 0px 0;
  }

  @include md {
    width: 80%;
    margin: 20px 0 0px 0;
  }

  @include lg {
    width: 80%;
    margin: 20px 0 0px 0;
  }

  @include xl {
    width: 80%;
    margin: 20px 0 0px 0;
  }

  span {
    font-size: 18px;
    font-family: "Gilroy", sans-serif;

    @include md {
      font-size: 15px;
    }
  }
  .top {
    margin: 20px 0 30px 0;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.6));
    .subIdInfo {
      border-radius: 10px 10px 0 0px;
      background-color: #29a9e0;
      padding: 10px;

      .ruble {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        @include md {
          justify-content: space-evenly;
        }

        .textInfo {
          color: #fff;

          .text {
            font-size: 16px;
          }

          .number {
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 5px;

            @include md {
              font-size: 18px;
            }
          }
        }
      }
      .ruble .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 10px;
        background: #fff;
      }
    }

    .balanceInfo {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background: #fff;
      padding: 20px;
      border-radius: 0 0 10px 10px;

      .text {
        color: #7a7a7a;
        font-size: 16px;
        font-weight: 500;
      }

      .balanceNumb {
        color: #222;
        font-size: 40px;
        font-weight: bold;
      }

      .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: none;
          width: 80%;
          background-color: #29a9e0;
          border-radius: 9px;
          color: #fff;
          margin-top: 10px;
          font-size: 15px;
          font-weight: bold;
          padding: 10px;
          cursor: pointer;
          transition: 0.3s;

          @include md {
            width: 80%;
          }

          @include xl {
            width: 60%;
          }

          &:hover {
            background-color: #0069b4;
          }

          &:active {
            transform: scale(0.98);
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .button {
      display: flex;
      justify-content: center;
      width: 70%;
      background-color: #29a9e0;
      border-radius: 9px;
      color: #fff;
      margin-top: 10px;
      font-size: 15px;
      font-weight: bold;
      padding: 10px;
      cursor: pointer;
      transition: 0.3s;
      text-transform: none;

      &:hover {
        background-color: #0069b4;
      }

      &:active {
        transform: scale(0.98);
      }

      @include sm {
        width: 70%;
      }

      @include md {
        width: 70%;
      }

      @include lg {
        width: 70%;
      }

      @include xl {
        width: 57%;
      }
    }

    // .forApp {
    //   display: flex;
    //   gap: 10px;

    //   .app {
    //     width: 70%;
    //     font-size: 15px;
    //     font-weight: bold;
    //     padding: 10px;
    //     cursor: pointer;
    //     transition: 0.3s;
    //     text-transform: none;
    //     background: #d8cece;
    //     border-radius: 10px;
    //     margin-top: 10px;
    //   }
    // }
  }
}
