@import "../../breakpoints.scss";

.feedback {
  background-color: #f0f0f0;
  min-height: 100vh;
  overflow: hidden;

  .feedbackContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 10vh);

    @include sm {
    }

    @include md {
    }

    @include lg {
    }

    .chatContainer {
      width: 80%;
      height: 85%;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      border: 1px solid rgba(255, 255, 255, 0.3);

      @include sm {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        box-shadow: none;
        -webkit-box-shadow: none;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
      }

      @include md {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        box-shadow: none;
        -webkit-box-shadow: none;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
      }

      @include lg {
        width: 100%;
        height: 100%;
        border-radius: 0px;
        box-shadow: none;
        -webkit-box-shadow: none;
        backdrop-filter: blur(0px);
        -webkit-backdrop-filter: blur(0px);
      }

      .chatHeader {
        display: flex;
        align-items: center;
        border-bottom: 1px solid lightgray;
        padding: 10px;
        height: 50px;
        justify-content: center;
        position: relative;

        .arrowIcon {
          color: #29a9e0;
          position: absolute;
          left: 10px;
          width: 35px;
          height: 35px;
          cursor: pointer;
        }

        span {
          font-size: 22px;
          font-weight: 500;
          color: #29a9e0;
        }
      }
    }
  }
}
