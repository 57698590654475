.payment {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 25vw;
    min-width: 415px;
    height: 30vh;
    gap: 20px;

    span {
      font-size: 24px;
      font-weight: 500;
    }

    input {
      border: none;
      outline: none;
      border-bottom: 2px solid #29a9e0;
      padding: 5px;
      background: transparent;
      width: 50%;
      font-size: 16px;
    }

    .button {
      display: flex;
      justify-content: center;
      cursor: pointer;
      padding: 10px;
      border: none;
      color: #fff;
      background-color: #29a9e0;
      font-weight: bold;
      width: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      user-select: none;
      border-radius: 5px;
      text-transform: none;
      //   margin-top: 10px;

      &:hover {
        background-color: #0069b4;
      }

      &:active {
        transform: scale(0.98);
      }
    }
  }
}
