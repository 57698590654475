.confirmPage {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;

  .confirmContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 33vw; //25
    min-width: 300px;
    height: 60vh; //40
    border-radius: 20px;
    padding: 2vh;

    .logo img {
      width: min(60vw, 250px);
      user-select: none;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;

      .idIcon {
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
        width: 100%;
        border: 1px solid #29a9e0;
        border-radius: 5px;
        padding: 5px;
      }

      input {
        border: none;
        outline: none;
        background: transparent;
        width: 100%;
        font-size: 16px;
        margin-left: 5px;

        &::placeholder {
          color: #29a9e0;
        }
      }
    }

    .button {
      display: flex;
      justify-content: center;
      cursor: pointer;
      padding: 10px;
      color: #fff;
      background-color: #29a9e0;
      font-weight: bold;
      width: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      user-select: none;
      text-transform: none;

      &:hover {
        background-color: #0069b4;
      }

      &:active {
        transform: scale(0.98);
      }
    }

    .errmsg {
      background-color: lightpink;
      color: firebrick;
      font-weight: bold;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .offscreen {
      position: absolute;
      left: -9999px;
    }
  }
}
