@import "../../breakpoints.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  background-color: #29a9e0;
  padding: 0 20px 0 30px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
    rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  width: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  position: static;

  .logo img {
    cursor: pointer;
    width: min(50vw, 180px);
    user-select: none;

    @include sm {
      width: min(50vw, 120px);
    }

    @include md {
      width: min(50vw, 120px);
    }

    @include lg {
      width: min(50vw, 120px);
    }
  }

  .buttonCont {
    display: flex;
    align-items: center;

    .logoutIcon {
      display: flex;
      align-self: center;
      margin-left: 10px;
      padding: 5px;
      user-select: none;
      text-transform: none;
      color: #fff;
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;
        @include sm {
          width: 17px;
        }

        @include md {
          width: 17px;
        }

        @include lg {
          width: 17px;
        }
      }
    }
  }

  // .forMobile {
  //   display: flex;
  //   gap: 15px;
  //   color: #fff;
  //   .forOis {
  //     display: flex;
  //     align-items: center;
  //     gap: 5px;
  //     cursor: pointer;
  //   }
  //   .forAndroid {
  //     cursor: pointer;
  //     display: flex;
  //     align-items: center;
  //     gap: 5px;
  //   }
  // }

  .name {
    padding: 7px;
    background-color: #fff;
    border-radius: 10px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: #29a9e0;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    .personIcon {
      display: flex;
      align-items: center;
      gap: 5px;

      svg {
        @include sm {
          width: 17px;
        }

        @include md {
          width: 17px;
        }

        @include lg {
          width: 17px;
        }
      }
    }

    @include sm {
      font-size: 14px;
      padding: 5px;
    }

    @include md {
      font-size: 10px;
    }

    @include lg {
      font-size: 10px;
    }
  }
}
