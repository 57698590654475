.message {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  .messageContent {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    gap: 5px;

    span {
      display: flex;
      flex-direction: column;
      color: gray;
      font-weight: 300;
    }

    .mess {
      background-color: lightgray;
      color: #222;
      padding: 10px 20px;
      border-radius: 0px 10px 10px 10px;
      max-width: max-content;
      display: flex;
      flex-wrap: wrap;

      .link {
        text-decoration: underline;
        color: #fff;
      }

      .date {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        gap: 5px;
        left: 10px;
        top: 5px;
        margin-left: auto;

        span {
          &:first-child {
            color: #686868;
          }
        }
      }
    }

    .dateT {
      position: relative;
      img {
        width: 200px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        border-radius: 5px;

        &:hover {
          opacity: 0.8;
        }
      }

      .imgBlock {
        position: absolute;
        right: 10px;
        bottom: 10px;
        padding: 5px;
        background-color: rgba(0, 105, 180, 0.7);
        border-radius: 10px;
        color: white;
      }
    }
  }

  &.owner {
    flex-direction: row-reverse;

    .messageContent {
      align-items: flex-end;

      .mess {
        background-color: #29a9e0;
        color: white;
        border-radius: 10px 0px 10px 10px;
        display: flex;
        flex-wrap: wrap;

        span {
          color: #d8d8d8;
        }
      }
    }
  }
}
