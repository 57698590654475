@import "../../breakpoints.scss";

.input {
  height: 50px;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid lightgrey;

  .textArea {
    width: 100%;
    border: none;
    outline: none;
    color: #5088a0;
    font-size: 18px;
    resize: none;

    &::placeholder {
      color: lightgray;
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.1);
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.4);
    }
    &::-webkit-scrollbar-thumb:active {
      background: rgba(0, 0, 0, 0.9);
    }
  }

  .send {
    display: flex;
    align-items: center;

    .img {
      width: 25px;
      height: 25px;
      cursor: pointer;
      color: #29a9e0;
      opacity: 0.8;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: none;
      color: white;
      cursor: pointer;

      .sendIcon {
        color: #29a9e0;
        width: 35px;
        height: 35px;
      }

      @include md {
        width: 80%;
      }

      @include xl {
        width: 60%;
      }
    }
  }
}
