.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
  color: #fff;
  font-size: 24px;
  font-weight: bold;

  .spinner {
    width: 50px;
    height: 50px;
    border: 8px solid;
    border-color: #29a9e0 transparent #29a9e0 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
    @keyframes spin-anim {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
