@import "../../breakpoints.scss";

.mainInfo {
  width: 70%;

  @include sm {
    margin-top: 20px;
    width: 70%;
  }

  @include md {
    width: 80%;
    margin-top: 20px;
  }

  @include lg {
    width: 80%;
    margin-top: 20px;
  }

  @include xl {
    width: 80%;
    margin-top: 20px;
  }

  .mainContainer {
    height: 100%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    background: #fff;
    display: flex;
    flex-direction: column;
    margin: 20px 0 30px 0;
  }

  span {
    font-size: 18px;
    font-family: "Gilroy", sans-serif;

    @include md {
      font-size: 15px;
    }
  }

  .top {
    // margin: 20px 0 30px 0;
    .infoCont {
      display: flex;
      gap: 32px;
      flex-wrap: wrap;

      .connectionInfo {
        display: flex;
        flex-direction: column;
        width: 40%;
        margin: 5px 0 10px 10px;
        color: #7a7a7a;
        border: 3px solid #29a9e0;
        padding: 15px;
        border-radius: 9px;

        @include md {
          width: 80%;
        }

        @include lg {
          width: 80%;
        }

        @include xl {
          width: 90%;
        }

        .conn {
          color: #29a9e0;
          font-weight: 500;

          span {
            font-size: 24px;
          }
        }
        .connection {
          margin-top: 5px;
          span {
            font-size: 15px;
            font-weight: bold;
            color: #7a7a7a;
          }
        }
      }
    }
    .connectedInfo {
      font-size: 15px;
      font-weight: 500;
      display: block;
      padding: 15px 0 5px 15px;
      font-family: "Gilroy", sans-serif;
    }
    .info {
      display: flex;
      flex-direction: column;
      width: 40%;
      margin: 5px 0 10px 10px;
      color: #7a7a7a;
      border: 3px solid #29a9e0;
      padding: 15px;
      border-radius: 9px;

      @include md {
        width: 80%;
      }

      @include lg {
        width: 80%;
      }

      @include xl {
        width: 90%;
      }

      .name {
        font-size: 24px;
        color: #29a9e0;
        font-weight: 500;
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 10px;
        span {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }

    .connected {
      font-size: 15px;
      font-weight: 500;
      display: block;
      padding: 15px 0 10px 15px;
      font-family: "Gilroy", sans-serif;
    }

    .payInfo {
      border: 3px solid #29a9e0;
      border-radius: 9px;
      width: 40%;
      padding: 15px;
      margin: 10px 0 10px 10px;

      .tariff {
        margin-bottom: 10px;
        color: #29a9e0;
        font-weight: 500;
        span {
          font-size: 24px;
        }
      }

      @include sm {
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-self: center;
      }

      @include md {
        width: 80%;
      }

      @include lg {
        width: 80%;
      }

      @include xl {
        width: 90%;
      }

      .amount {
        color: #7a7a7a;
        font-size: 15px;
        font-weight: bold;
      }

      .payText {
        display: flex;
        flex-direction: column;

        span {
          font-size: 15px;
          font-weight: bold;
          color: #7a7a7a;
        }
      }
    }

    .autoPay {
      border: 3px solid #29a9e0;
      border-radius: 9px;
      width: 40%;
      padding: 15px;
      margin: 10px 0 10px 10px;

      .sett {
        margin-bottom: 10px;
        color: #29a9e0;
        font-weight: 500;
        span {
          font-size: 24px;
        }
      }
      .toggleText {
        display: flex;
        align-items: center;
        gap: 10px;
        span {
          font-size: 15px;
          font-weight: bold;
          color: #7a7a7a;
        }
      }

      @include md {
        width: 80%;
      }

      @include lg {
        width: 80%;
      }

      @include xl {
        width: 90%;
      }
    }
  }

  .bottom {
    // display: none;
    margin-top: 5vh;
    .services {
      font-size: 15px;
      margin: 10px 0 10px 15px;
      font-weight: 500;
      font-family: "Gilroy", sans-serif;
    }

    .blocks {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px;
      margin: 20px 0 0 20px;

      @include sm {
        // display: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* flex-wrap: wrap; */
        gap: 20px;
        margin: 20px 0 20px 0px;
      }

      @include md {
        // display: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* flex-wrap: wrap; */
        gap: 20px;
        margin: 20px 0 20px 0px;
      }

      @include lg {
        // display: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* flex-wrap: wrap; */
        gap: 20px;
        margin: 20px 0 20px 0px;
      }

      @include xl {
        // display: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* flex-wrap: wrap; */
        gap: 20px;
        margin: 20px 0 20px 0px;
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        color: #fff;
        border-radius: 10px;
        padding: 10px;
        width: 200px;
        height: 80px;
        cursor: pointer;
        user-select: none;

        &:nth-child(1) {
          background-color: #29a9e0;
        }
        &:nth-child(2) {
          background-color: #27ae60;
        }

        &:nth-child(3) {
          background-color: #e74c3c;
        }

        &:nth-child(4) {
          background-color: #16a085;
        }

        &:nth-child(5) {
          background-color: #8e44ad;
        }
      }
    }
  }
}
