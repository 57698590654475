@import "../../breakpoints.scss";

.createPsw {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;

  .loginContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    // background: #fff;
    width: 33vw; //25
    min-width: 415px;
    height: 60vh; //40
    border-radius: 20px;
    padding: 2vh;
    // box-shadow: 0px 1px 4px rgba(29, 29, 29, 0.25);

    .logo img {
      width: min(60vw, 300px);
      user-select: none;
    }

    .cabinetText {
      font-size: 18px;
      color: #000;
      font-weight: 500;
    }

    .inputs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      position: relative;
      margin-top: 15px;

      .forgetPsw {
        position: absolute;
        bottom: -10px;
        right: -5px;
        cursor: pointer;
        user-select: none;
        color: #222;
        font-size: 14px;
      }
      @include sm {
        width: 50%;
      }

      @include md {
        width: 50%;
      }

      .idIcon {
        display: flex;
        align-items: center;
        margin-bottom: 3vh;
        width: 100%;
        border: 1px solid #29a9e0;
        border-radius: 5px;
        padding: 5px;
        position: relative;

        span {
          position: absolute;
          bottom: -20px;
          color: red;
          display: none;
          font-size: 14px;

          @include sm {
            bottom: -15px;
            font-size: 12px;
          }

          @include md {
            bottom: -15px;
            font-size: 12px;
          }
        }
      }

      input {
        border: none;
        outline: none;
        background: transparent;
        width: 100%;
        font-size: 16px;
        margin-left: 5px;

        &:invalid[focused="true"] ~ span {
          display: block;
        }

        &::placeholder {
          color: #29a9e0;
        }
      }
    }

    .button {
      display: flex;
      justify-content: center;
      cursor: pointer;
      padding: 10px;
      color: #fff;
      background-color: #29a9e0;
      font-weight: bold;
      width: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      user-select: none;
      border-radius: 5px;
      margin-top: 10px;
      text-transform: none;

      .timeSpan {
        display: none;
      }

      &:hover {
        background-color: #0069b4;
      }

      &:active {
        transform: scale(0.98);
      }

      @include sm {
        width: 50%;
      }

      @include md {
        width: 50%;
      }

      @include lg {
        width: 50%;
      }
    }

    .errmsg {
      background-color: lightpink;
      color: firebrick;
      font-weight: bold;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .offscreen {
      position: absolute;
      left: -9999px;
    }
  }
}
