@import "../../breakpoints.scss";

.history {
  background-color: #f0f0f0;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;

  @include sm {
    height: auto;
  }

  @include md {
    height: auto;
  }

  @include lg {
    height: auto;
  }

  @include xl {
    height: auto;
  }

  .paymentContainer {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    margin: 30px 30px 0 30px;
    height: 75vh;

    @include sm {
      margin: 0;
      gap: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: auto;
    }

    @include md {
      margin: 0;
      gap: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: auto;
    }

    @include lg {
      margin: 0;
      gap: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: auto;
    }

    @include xl {
      margin: 0;
      gap: 0;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      height: auto;
    }

    .mainContainer {
      height: 100%;
      width: 70%;
      display: flex;
      flex-direction: column;

      .accordion {
        display: none;
        margin-top: 10px;
        background-color: #fff;
        border-radius: 0 0 5px 5px;

        .filter {
          display: flex;
          flex-direction: column;
          gap: 6px;

          .button {
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: none;
            width: 50%;
            background-color: #29a9e0;
            border-radius: 9px;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            padding: 5px;
            cursor: pointer;
          }
        }

        @include sm {
          display: flex;
          flex-direction: column;
        }

        @include md {
          display: flex;
          flex-direction: column;
        }

        @include lg {
          display: flex;
          flex-direction: column;
        }

        @include xl {
          display: flex;
          flex-direction: column;
        }
      }

      .filter.remove {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        @include sm {
          display: none;
        }

        @include md {
          display: none;
        }

        @include lg {
          display: none;
        }

        @include xl {
          display: none;
        }

        .date {
          @include sm {
            margin-bottom: 5px;
          }

          @include md {
            margin-bottom: 5px;
          }

          @include lg {
            margin-bottom: 5px;
          }

          @include xl {
            margin-bottom: 5px;
          }
        }

        @include sm {
          flex-direction: column;
        }

        @include md {
          flex-direction: column;
        }

        @include lg {
          flex-direction: column;
        }

        @include xl {
          flex-direction: column;
        }

        .button {
          display: flex;
          justify-content: center;
          align-items: center;
          text-transform: none;
          width: 13%;
          background-color: #29a9e0;
          border-radius: 9px;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          padding: 5px;
          cursor: pointer;
          transition: 0.3s;

          @include sm {
            width: 40%;
          }

          @include md {
            width: 40%;
          }

          @include lg {
            width: 40%;
          }

          @include xl {
            width: 40%;
          }

          &:hover {
            background-color: #0069b4;
          }

          &:active {
            transform: scale(0.98);
          }
        }
      }

      @include sm {
        width: 70%;
        margin: 20px 0 0px 0;
      }

      @include md {
        width: 80%;
        margin: 20px 0 0px 0;
      }

      @include lg {
        width: 80%;
        margin: 20px 0 0px 0;
      }

      @include xl {
        width: 80%;
        margin: 20px 0 0px 0;
      }

      .back {
        display: flex;
        align-items: center;

        svg {
          width: 45px;
          height: 45px;
          cursor: pointer;

          @include sm {
            width: 35px;
            height: 35px;
          }

          @include md {
            width: 35px;
            height: 35px;
          }

          @include lg {
            width: 35px;
            height: 35px;
          }

          @include xl {
            width: 35px;
            height: 35px;
          }
        }

        span {
          font-size: 18px;
          font-family: "Gilroy", sans-serif;
          margin-left: 15px;

          @include sm {
            font-size: 15px;
          }

          @include md {
            font-size: 15px;
          }

          @include lg {
            font-size: 15px;
          }

          @include xl {
            font-size: 15px;
          }
        }
      }
    }

    .payments {
      display: flex;
      flex-direction: column;
      margin: 20px 0 0 0;
      overflow-y: overlay;
      scroll-behavior: smooth;
      padding-bottom: 20px;
      height: 75vh;
      overflow-x: hidden;
      padding-right: 30px;
      padding-left: 5px;

      @include sm {
        padding-right: 21px;
        height: 53vh;
      }

      @include md {
        padding-right: 21px;
        height: 53vh;
      }

      @include lg {
        padding-right: 21px;
        height: 53vh;
      }

      @include xl {
        padding-right: 21px;
        height: 53vh;
      }

      &::-webkit-scrollbar {
        width: 7px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }

      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        outline: 1px solid slategrey;
        border-radius: 5px;
      }

      .items {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .item {
          display: flex;
          align-items: center;
          width: 100%;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          background-color: #fff;
          border-radius: 10px;
          padding: 10px 0px 10px 10px;

          @include sm {
            padding: 0px 0px 0px 5px;
          }

          @include md {
            padding: 0px 0px 0px 5px;
          }

          @include lg {
            padding: 0px 0px 0px 5px;
          }

          @include xl {
            padding: 0px 0px 0px 5px;
          }

          .amount {
            background-color: #29a9e0;
            color: #fff;
            height: 100%;
            width: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 0px 10px 10px 0px;
            font-size: 24px;
            font-weight: bold;
            margin-left: auto;

            @include sm {
              width: 22%;
              padding: 0px;
              font-size: 13px;
            }

            @include md {
              width: 22%;
              padding: 0px;
              font-size: 13px;
            }

            @include lg {
              width: 22%;
              padding: 0px;
              font-size: 13px;
            }

            @include xl {
              width: 22%;
              padding: 0px;
              font-size: 13px;
            }
          }

          .payText {
            display: flex;
            flex-direction: column;
            margin-left: 25px;

            @include sm {
              margin-left: 10px;
              width: 45%;
            }

            @include md {
              margin-left: 10px;
              width: 45%;
            }

            @include lg {
              margin-left: 10px;
              width: 45%;
            }

            @include xl {
              margin-left: 10px;
              width: 45%;
            }

            .mainText {
              color: #29a9e0;
              font-size: 20px;
              font-weight: 500;

              @include sm {
                font-size: 12px;
              }

              @include md {
                font-size: 12px;
              }

              @include lg {
                font-size: 12px;
              }

              @include xl {
                font-size: 12px;
              }
            }

            .textTime {
              color: #7a7a7a;

              @include sm {
                font-size: 12px;
              }

              @include md {
                font-size: 12px;
              }

              @include lg {
                font-size: 12px;
              }

              @include xl {
                font-size: 12px;
              }
            }
          }
        }

        .icon {
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          padding: 10px;
          background: #29a9e0;

          @include sm {
            width: 20px;
            height: 20px;
            padding: 7px;
          }

          @include md {
            width: 20px;
            height: 20px;
            padding: 10px;
          }

          @include lg {
            width: 20px;
            height: 20px;
            padding: 10px;
          }

          @include xl {
            width: 20px;
            height: 20px;
            padding: 10px;
          }
        }
      }
    }
  }
}
